<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          size="small"
          placeholder="选择服务配置项"
          v-model="query.type"
          @on-change="handleChangeDict"
        >
          <Option
            v-for="item in dictArray"
            :key="'dict_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>

      <i-col span="4">
        <Button
          type="primary"
          class="m-r-5"
          size="small"
          icon="ios-search"
          @click="handleSearch"
        >搜索</Button>
        <Button
          type="success"
          size="small"
          @click="handleAddVersion"
        >新增版本</Button>
      </i-col>
    </Row>
    <Table
      stripe
      size="small"
      :data="tableList"
      :columns="tableColumns"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handleChangePage"
      ></Page>
    </div>
    <Modal
      v-model="addVersionModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >{{optionType===1?'新增版本':'编辑版本'}}</p>
      <div class="m-b-10">
        <span class="m-r-5">当前选择配置项</span>
        <Tag color="primary">{{currentDict.name}}</Tag>
      </div>
      <div v-if="currentDict.desc&&currentDict.desc.length">
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="12">
            <div class="p-b-5">配置描述</div>
            <pre>{{currentDict.desc}}</pre>
          </i-col>
          <i-col span="12">
            <div class="p-b-5">配置示例</div>
            <pre>{{verTemplate}}</pre>
          </i-col>
        </Row>
        <div
          v-show="inserItem"
          class="m-b-10"
        >
          <div class="p-b-5">已配置信息</div>
          <detail
            ref="insertDetail"
            id="insert"
            :titleDesc="currentDict.desc"
            :list="oldConfigContent"
          ></detail>
        </div>
        <div>
          <div class="p-b-5">配置信息</div>
          <Row :gutter="8">
            <i-col
              span="12"
              v-for="(item,index) in configContent"
              :key="'config_'+index"
            >
              <Card class="m-b-10">
                <p slot="title">
                  {{'配置'+(index+1)}}
                </p>
                <a
                  href="javascript:void(0);"
                  slot="extra"
                  title="删除"
                  @click="handleDelItem(index)"
                >
                  <Icon
                    type="ios-trash"
                    size="22"
                    color="red"
                  />
                </a>
                <Row
                  :gutter="8"
                  class="m-b-5"
                  v-for="(val,key,index2) in item"
                  :key="'item_'+index+'_'+key"
                >
                  <i-col span="8">{{key}}</i-col>
                  <i-col
                    span="16"
                    v-if="(item[key] instanceof Array)"
                  >
                    <Card
                      :gutter="8"
                      class="m-b-5"
                      v-for="(childItem,childIndex) in item[key]"
                      :key="'child_'+index+'_'+index2+'_'+childIndex"
                    >
                      <Row>
                        <i-col span="21">
                          <Row
                            :gutter="8"
                            class="m-b-5"
                            v-for="(childVal,childKey) in childItem"
                            :key="'child_'+index+'_'+index2+'_'+childIndex+'_'+childKey"
                          >
                            <i-col span="8">{{childKey}}</i-col>
                            <i-col span="16">
                              <Input
                                v-model="childItem[childKey]"
                                size="small"
                              />
                            </i-col>
                          </Row>
                        </i-col>
                        <i-col
                          span="3"
                          class="text-center"
                        >
                          <a
                            href="javascript:void(0);"
                            title="删除子项"
                            @click="handleDelChild(item[key],childIndex)"
                          >
                            <Icon
                              type="ios-trash"
                              size="22"
                              color="red"
                            />
                          </a>
                        </i-col>
                      </Row>
                    </Card>
                    <div>
                      <Button
                        type="success"
                        size="small"
                        @click="hanldeAddChild(item[key])"
                      >新增子项</Button>
                    </div>
                  </i-col>
                  <i-col
                    v-else
                    span="16"
                  >
                    <Input
                      v-model="item[key]"
                      size="small"
                    />
                  </i-col>
                </Row>
              </Card>
            </i-col>
          </Row>
          <div>
            <Button
              type="success"
              size="small"
              @click="handleAddItem"
            >新增配置</Button>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div v-if="inserItem">
          <Button
            type="success"
            class="m-r-5"
            @click="handleConfirmAddItem"
          >确认新增</Button>
        </div>
        <div v-else>
          <div v-if="currentDict.template!==''">
            <Button
              v-if="curVersionStatus===0"
              type="primary"
              class="m-r-5"
              @click="handleConfirmAdd(0)"
            >保存为草稿</Button>
            <Button
              v-if="curVersionStatus===0"
              type="success"
              class="m-r-5"
              @click="handleConfirmAdd(1)"
            >提交版本</Button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal
      v-model='detailModal'
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >查看版本配置详情</p>
      <detail
        ref="versionDetail"
        id="view"
        :titleDesc="currentDict.desc"
        :list="configContent"
      ></detail>
    </Modal>

  </div>
</template>

<script>
import Detail from './Detail.vue'
import { getDictList, getVersionPage, addVersion, updateVersion, applyVersion } from '@/api/sys/version'
export default {
  components: {
    Detail
  },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 15,
        companyId: null,
        publisherId: null,
        type: null
      },
      dictArray: [],
      currentDict: {},
      verTemplate: '', // 版本模板
      tableList: [],
      total: 0,
      tableColumns: [
        { title: '版本号', align: 'center', key: 'version' },
        { title: '公司', align: 'center', key: 'companyName' },
        {
          title: '启用状态',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.active ? '启用中' : '停用')
          }
        },
        {
          title: '编辑状态',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.status === 0 ? '草稿' : '已提交')
          }
        },
        { title: '更新时间', align: 'center', key: 'updateTime' },
        {
          title: '操作',
          render: (h, params) => {
            const html = []
            html.push(h('a', {
              style: { marginRight: '5px' },
              on: {
                click: () => {
                  this.handleViewVersion(params.row)
                }
              }
            }, '查看'))
            if (params.row.status === 0) { // 草稿状态
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleEidtVersion(params.row)
                  }
                }
              }, '编辑'))
            }
            if (params.row.status === 1) { // 已提交状态
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleCopyVersion(params.row)
                  }
                }
              }, '复制版本'))
            }

            if (params.row.status === 1) {
              if (params.row.active) {
                html.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleInsertItem(params.row)
                    }
                  }
                }, '新增配置'))
              } else {
                html.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleApplyVersion(params.row)
                    }
                  }
                }, '启用'))
              }
            }

            return h('div', html)
          }
        }
      ],
      optionType: 1, // 1：新增，2：查看/编辑
      curVersionInfo: {},
      curVersionStatus: 0,
      curVersionNum: null,
      addVersionModal: false,
      configContent: [],
      configModel: {
        companyId: null,
        data: '',
        publisherId: null,
        status: 0,
        type: null
      },
      detailModal: false,
      oldConfigContent: [], // 旧配置内容，用于新增子项操作
      inserItem: false // 新增子项
    }
  },
  created () {
    this.query.publisherId = this.$store.getters.token.userInfo.publisherId
    this.getDictData()
  },
  methods: {
    /**
     * 获取服务配置项字典数据
     */
    getDictData () {
      getDictList().then(res => {
        if (res && !res.errcode) {
          this.dictArray = res
          if (this.dictArray.length) {
            this.currentDict = this.dictArray[0]
            this.query.type = this.dictArray[0].id
            this.getVersionData()
          }
        } else {
          this.dictArray = []
        }
      })
    },
    getVersionData () {
      getVersionPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableList = res.records
          this.total = res.total
          this.pageNumber = res.current
        } else {
          this.tableList = []
          this.total = 0
          this.pageNumber = 1
        }
      })
    },
    handleChangeDict () {
      this.currentDict = this.dictArray.find(x => x.id === this.query.type)
      this.configContent = []
      this.handleSearch()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getVersionData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getVersionData()
    },
    handleAddVersion () {
      this.addVersionModal = true
      this.inserItem = false
      this.optionType = 1
      this.configContent = JSON.parse(JSON.stringify(this.verTemplate))
    },
    handleViewVersion (params) {
      this.detailModal = true
      this.inserItem = false
      this.configContent = JSON.parse(params.data)
      this.$refs.versionDetail.initData()
    },
    /**
     * 编辑版本
     */
    handleEidtVersion (params) {
      this.addVersionModal = true
      this.inserItem = false
      this.configContent = JSON.parse(params.data)
      this.curVersionStatus = params.status
      this.curVersionNum = params.version
      this.optionType = 2
    },
    /**
     * 启用版本
     */
    handleApplyVersion (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要启用该版本？',
        onOk: () => {
          const postData = {
            companyId: params.publisherId,
            publisherId: params.publisherId,
            type: params.type,
            version: params.version
          }
          applyVersion(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getVersionData()
            }
          })
        }
      })
    },
    /**
     * 复制版本
     */
    handleCopyVersion (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要复制该版本？',
        onOk: () => {
          const postData = {
            companyId: params.companyId,
            data: params.data,
            publisherId: params.publisherId,
            status: 0,
            type: params.type
          }
          addVersion(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getVersionData()
            }
          })
        }
      })
    },
    /**
     * 列表新增配置项
     */
    handleInsertItem (params) {
      this.addVersionModal = true
      this.inserItem = true
      this.optionType = 2
      this.configContent = JSON.parse(JSON.stringify(this.verTemplate))
      this.oldConfigContent = JSON.parse(params.data)
      this.curVersionInfo = params
      this.$refs.insertDetail.initData()
    },
    /**
     * 新增配置项
     */
    handleAddItem () {
      const item = JSON.parse(JSON.stringify(this.verTemplate[0]))
      this.configContent.push(item)
    },
    /**
     * 删除配置项
     */
    handleDelItem (index) {
      if (this.configContent.length <= 1) {
        this.$Notice.warning({ desc: '无法删除，至少保留一项配置。' })
        return false
      }

      this.configContent.splice(index, 1)
    },
    /**
     * 新增子项
     */
    hanldeAddChild (childArray) {
      const insertChild = JSON.parse(JSON.stringify(childArray[0]))
      childArray.push(insertChild)
    },
    /**
     * 删除子项配置
     */
    handleDelChild (childArray, delIndex) {
      if (childArray.length <= 1) {
        this.$Notice.warning({ desc: '无法删除，至少保留一个子项。' })
        return false
      }
      childArray.splice(delIndex, 1)
    },
    /**
     * 保存草稿/提交版本
     */
    handleConfirmAdd (status) {
      this.$Modal.confirm({
        title: '操作提示',
        content: status === 0 ? '确定将该版本信息保存为草稿？' : '确定提交该版本信息？提交后将无法编辑。',
        onOk: () => {
          this.inserItem = false

          this.configModel.companyId = this.query.publisherId
          this.configModel.data = JSON.stringify(this.configContent)
          this.configModel.publisherId = this.query.publisherId
          this.configModel.status = status
          this.configModel.type = this.currentDict.id
          if (this.optionType === 1) {
            // 新增
            addVersion(this.configModel).then(res => {
              this.addVersionModal = false
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                Object.assign(this.$data.configModel, this.$options.data().configModel)
                this.getVersionData()
              }
            })
          } else {
            const postData = Object.assign({ version: this.curVersionNum }, this.configModel)
            updateVersion(postData).then(res => {
              this.addVersionModal = false
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.getVersionData()
              }
            })
          }
        }
      })
    },
    /**
     * 确认新增子项
     */
    handleConfirmAddItem () {
      const configData = this.oldConfigContent.concat(this.configContent)
      const postData = {
        companyId: this.curVersionInfo.companyId,
        publisherId: this.curVersionInfo.publisherId,
        status: this.curVersionInfo.status,
        type: this.curVersionInfo.type,
        version: this.curVersionInfo.version,
        data: JSON.stringify(configData)
      }
      updateVersion(postData).then(res => {
        this.addVersionModal = false
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.getVersionData()
        }
      })
    }

  },
  watch: {
    currentDict: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && Object.keys(val).length) {
          if (val.template !== '') {
            const strData = JSON.stringify(val.template).replace(/\\"/g, '"').slice(1, -1)
            this.verTemplate = JSON.parse(strData)
          }
        }
      }
    }
  }
}
</script>
