import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取版本字典集合
 * @param {Object} data
 * @returns
 */
export function getDictList (data) {
  return request({
    url: '/ooh-sys/v1/version/getdictlist',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 获取版本分页
 * @param {Object} data
 * @returns
 */
export function getVersionPage (data) {
  return request({
    url: '/ooh-sys/v1/version/getversionpage',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 新增版本
 * @param {Object} data
 * @returns
 */
export function addVersion (data) {
  return request({
    url: '/ooh-sys/v1/version/addversion',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 更新版本
 * @param {Object} data
 * @returns
 */
export function updateVersion (data) {
  return request({
    url: '/ooh-sys/v1/version/updateversion',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 应用版本
 * @param {Object} data
 * @returns
 */
export function applyVersion (data) {
  return request({
    url: '/ooh-sys/v1/version/applyversion',
    method: 'POST',
    data: qs.stringify(data)
  })
}
